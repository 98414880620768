import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";
import styled from "styled-components";
import BreakpointDown from "../components/Media/BreakpointDown";
import {
  Section,
  BreadCrumb,
  SectionTitle,
  SectionPageTitle,
} from "../components/Section";
import { HomeIcon, ArrowRight } from "../components/Icons";
import RequestForm from "../components/RequestForm";

const GetStartedWrapper = styled.div`
  display: flex;
  ${BreakpointDown.sm`
   margin-top:20px;
  `}
`;

const GetStartedWrapperItem = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const PaymentCardWapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  z-index: 2;
  justify-content: center;
  @media (max-width: 1280px) {
    margin-left: -10px;
    margin-right: -10px;
  }
`;
const PaymentCardItem = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  @media (max-width: 1280px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  ${BreakpointDown.lg`
     flex: 0 0 50%;
     max-width: 50%;
     margin-bottom:10px;
     padding-left: 15px;
     padding-right: 15px;
  `}
  ${BreakpointDown.sm`
     flex: 0 0 100%;
     max-width: 100%;
  `}
`;
const PaymentCardSection = styled(Section)`
  margin-top: -74px;
  ${BreakpointDown.sm`
     margin-top: -105px;
  `}
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 50px #0000000f;
  border: 1px solid #cbd1e2;
  border-radius: 5px;
  position: relative;
  padding: 45px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  height: 100%;
  @media (max-width: 1440px) {
    padding: 20px 10px;
  }
  ${BreakpointDown.xl`
    padding:15 10px;
  `}

  &:before {
    position: absolute;
    left: 5px;
    top: 5px;
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid #ebf0ff;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #ebf0ff;
  }
`;
const CardSubtitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  word-break: break-word;
  a{
    color: #121316;
  }
  @media (max-width: 1366px) {
    font-size: 16px;
  }
`;
const Title = styled.div`
  color: #223c7e;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 5px;
  @media (max-width: 1440px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const FormWrapper = styled.div`
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  .form-flex {
    .form-group {
      &:last-child {
        max-width: 100%;
        .form-action {
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }
`;

function ContactUsPage() {
  return (
    <Layout pageName="contcat-us">
      <SEO title="Contact Us" description="Contact Us" />
      <Section
        bgColor="rgba(203, 209, 226, 0.55)"
        pt="180px"
        xpt="60px"
        pb="111px"
      >
        <BreadCrumb top="75" xtop="15px">
          <div className="container">
            <Link to="/">
              <HomeIcon /><ArrowRight /></Link><Link className="line-after-heading">Contact Us</Link>
          </div>
        </BreadCrumb>
        <div className="container">
          <GetStartedWrapper>
            <GetStartedWrapperItem>
              <SectionPageTitle
                className="h2"
                textAlign="center"
                mt="20px"
                ml="0"
                mb="15px"
              >
                Contact Us
              </SectionPageTitle>
              {/* <p>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas.
              </p> */}
            </GetStartedWrapperItem>
          </GetStartedWrapper>
        </div>
      </Section>
      <PaymentCardSection bgColor="transpasrent" className="gradinat-wrapper">
        <div className="container">
          <PaymentCardWapper>
            <PaymentCardItem>
              <Card>
                <Title>Office Address</Title>
                <CardSubtitle>117 S. Main St, Medina, TN 38355</CardSubtitle>
              </Card>
            </PaymentCardItem>
            <PaymentCardItem>
              <Card>
                <Title>Mailing Address</Title>
                <CardSubtitle>P.O. Box 448, Medina, TN 38355</CardSubtitle>
              </Card>
            </PaymentCardItem>
            <PaymentCardItem>
              <Card>
                <Title>Email Address</Title>
                <CardSubtitle><a href="mailto:customerservice@jagbarns.com">customerservice@jagbarns.com</a></CardSubtitle>
              </Card>
            </PaymentCardItem>
            <PaymentCardItem>
              <Card>
                <Title>Contact Information</Title>
                <CardSubtitle><a href="tel:7314834930">(731) 483-4930</a></CardSubtitle>
              </Card>
            </PaymentCardItem>
          </PaymentCardWapper>
        </div>
      </PaymentCardSection>
      <Section pt="100px" pb="130px" xpt="40px" mpb="60px" mpt="40px">
        <SectionTitle textAlign="center">Get In Touch</SectionTitle>
        <div className="container">
          <FormWrapper>
            <RequestForm />
          </FormWrapper>
        </div>
      </Section>
    </Layout>
  );
}

export default ContactUsPage;
